<template>
    <div ref="dashBoardFrame"
        class="absolute top-0 left-0 h-full w-full flex justify-center items-center transition-all duration-700 opacity-0 hidden">
        <img src="../assets/img/background-main.png" class="h-full w-full object-cover">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div class="w-[90%] h-[80%] flex justify-start items-start">
                <div class="w-full h-[95%] flex flex-col justify-start items-center overflow-y-scroll no-scrollbar">
                    <div
                        class="w-full h-[200px] min-h-[200px] border border-[#ffffff59] rounded-xl bg-[#181818] flex flex-col justify-between items-center mt-[2%]">
                        <div class="w-[95%] h-auto flex justify-between mt-[3%]">
                            <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[21px]">{{
                                userTotalPoints }}</h1>
                            <img src="../assets/img/xDefin.webp" class="h-[30px]">
                        </div>
                        <div class="w-[95%] h-auto -translate-y-[7px]">
                            <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh]">$vDEF</h1>
                        </div>
                        <div @click="walletActionController"
                            class="w-[95%] h-9 rounded-lg bg-[#cefb2d28] flex justify-center items-center gap-2 transition-all duration-300 active:scale-90">
                            <img v-if="!walletAddress" src="../assets/img/walletnotconnect.webp"
                                class="h-[17px] -translate-y-[1.5px]">
                            <h1 class="font-montserrat text-[#cefb2d81] font-[700] leading-none text-[2vh]">
                                {{ walletAddress ? 'Disconnect ( ' + walletAddress + ' )' : 'Connect Wallet' }}
                            </h1>
                            <img v-if="walletAddress" src="../assets/img/walletconnected.webp"
                                class="h-[17px] -translate-y-[1.5px]">
                        </div>
                        <div class="w-[95%] h-auto translate-y-[5px]">
                            <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh]">Balance</h1>
                        </div>
                        <div class="w-[95%] h-9 flex justify-between items-center mb-[3%]">
                            <div class="w-auto h-full flex gap-2">
                                <img src="../assets/img/toncoin-ton-logo.webp" class="h-full">
                                <div class="w-auto h-full flex flex-col justify-center gap-1">
                                    <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh]">TON
                                    </h1>
                                    <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh]">{{
                                        tonBalance }} $TON</h1>
                                </div>
                            </div>
                            <div @click="withDraw"
                                class="h-full aspect-square rounded-lg bg-[#cefb2d28] flex justify-center items-center transition-all duration-300 active:scale-90">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[3.5vh] pb-1">
                                    &uarr;</h1>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="relative w-full h-fit border border-[#d9d9d97e] rounded-lg mt-[2%]">
                        <img src="../assets/img/dashboard/background-balance.png" class="w-full rounded-lg">
                        <div
                            class="absolute top-0 left-0 w-full h-full flex flex-col gap-[5%] justify-center items-center ">
                            <h1 class="font-montserrat text-[#D9D9D9] font-[500] leading-none text-[2vh] mt-[1%]">
                                Total $vDEF</h1>
                            <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[4vh]">{{ userTotalPoints }}</h1>
                        </div>
                    </div> -->
                    <div class="w-full aspect-[6/1] flex justify-between items-center mt-[10%]">
                        <div class="relative h-full w-[48%]">
                            <img src="../assets/img/dashboard/background-main-button.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] mb-[3%]">
                                    Channel</h1>
                            </div>
                            <div class="absolute bottom-[36%] left-0 w-full h-full flex justify-center items-center">
                                <div class="w-[30%] aspect-square flex justify-center items-center">
                                    <img @click="channelClick" src="../assets/img/dashboard/telechannel.webp"
                                        class="w-[85%] transition-all active:scale-90">
                                </div>
                            </div>
                        </div>
                        <div class="relative h-full w-[48%]">
                            <img src="../assets/img/dashboard/background-main-button.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] mb-[3%]">
                                    Group</h1>
                            </div>
                            <div class="absolute bottom-[36%] left-0 w-full h-full flex justify-center items-center">
                                <div class="w-[30%] aspect-square flex justify-center items-center">
                                    <img @click="groupClick" src="../assets/img/dashboard/telegroup.webp"
                                        class="w-[85%] transition-all active:scale-90">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full aspect-[6/1] flex justify-between items-center mt-[10%]">
                        <div class="relative h-full w-[48%]">
                            <img src="../assets/img/dashboard/background-main-button.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] mb-[3%]">
                                    Twitter</h1>
                            </div>
                            <div class="absolute bottom-[36%] left-0 w-full h-full flex justify-center items-center">
                                <div class="w-[30%] aspect-square flex justify-center items-center">
                                    <img @click="twitterClick" src="../assets/img/dashboard/x.webp"
                                        class="w-[85%] transition-all active:scale-90">
                                </div>
                            </div>
                        </div>
                        <div class="relative h-full w-[48%]">
                            <img src="../assets/img/dashboard/background-main-button.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] mb-[3%]">
                                    Website</h1>
                            </div>
                            <div class="absolute bottom-[36%] left-0 w-full h-full flex justify-center items-center">
                                <div class="w-[30%] aspect-square flex justify-center items-center">
                                    <img @click="websiteClick" src="../assets/img/dashboard/btn-dapp.webp"
                                        class="w-[85%] active:scale-90">
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="../assets/img/dashboard/line.webp" class="w-full mt-[10%] mb-[8%]">
                    <div class="w-full aspect-[9/1] flex justify-start items-center">
                        <div class="h-full aspect-square">
                            <img src="../assets/img/dashboard/history.webp" class="h-full max-h-[50px]">
                        </div>
                        <div class="h-full w-full flex flex-col justify-center items-start gap-[10%]">
                            <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] ml-[3%]">Your
                                point history</h1>
                            <div class="h-fit w-full flex justify-start items-center gap-[2%]">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.6vh] ml-[3%]">
                                    Total tasks completed:</h1>
                                <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[1.6vh]">{{
                                    pointHistory.length }}</h1>
                            </div>
                        </div>
                    </div>
                    <div v-for="history in pointHistory" :key="history"
                        class="w-full aspect-[10/1] flex justify-center items-center mt-[2%]">
                        <div class="w-[90%] h-full flex justify-center items-center">
                            <div class="w-[70%] h-full flex justify-start items-center">
                                <img src="../assets/img/points.webp" class="h-[60%] max-h-[40px]">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.6vh] ml-[5%]">
                                    {{ history.taskName }}</h1>
                            </div>
                            <div class="w-[30%] h-full flex justify-end items-center">
                                <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[1.6vh]">+ {{
                                    history.point }}</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useState } from '../assets/controller/state';
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { Pagination, Autoplay } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';
import * as definAPI from '../assets/controller/api';
import * as Utils from '../assets/controller/utilities';
import { TonConnectUI } from '@tonconnect/ui'

export default {
    name: 'DashBoardDefin',
    components: {
        // Swiper,
        // SwiperSlide,
    },
    setup(props, { expose }) {
        const state = useState();
        const dashBoardFrame = ref(null);
        let tonConnectUI;
        const eventData = ref([
            { img: require('../assets/img/dashboard/Event1.webp') },
            { img: require('../assets/img/dashboard/Event2.webp') },
            { img: require('../assets/img/dashboard/Event3.webp') },
            { img: require('../assets/img/dashboard/Event4.webp') },
            { img: require('../assets/img/dashboard/Event5.webp') },
        ]);
        const userBalance = ref();
        const userTotalPoints = ref(0);
        const pointHistory = ref([]);
        const tonBalance = ref(0);
        const walletAddress = ref(null);

        function initTonConnect() {

            const ORIGINAL_PATH_URL = String(process.env.VUE_APP_WALLET_MANIFEST_URL);

            tonConnectUI = new TonConnectUI({
                manifestUrl: ORIGINAL_PATH_URL,
            });

            tonConnectUI.uiOptions = {
                actionsConfiguration: {
                    twaReturnUrl: 'https://t.me/xdefin_bot'
                }
            };

            tonConnectUI.connectionRestored.then(restored => {
                console.log(restored);
                updateTonAddress();
            });

            setInterval(() => {
                if (!walletAddress.value) {
                    updateTonAddress();
                }
            }, 1000);
        }

        const updateTonAddress = () => {
            try {
                let address = tonConnectUI.wallet.account.address;
                if (address.length > 9) {
                    address = address.substring(0, 4) + '.......' +
                        address.substring(address.length - 4);
                }
                walletAddress.value = address;
            } catch (error) {
                // console.log(error);
            }
        }

        async function connectWallet() {
            tonConnectUI.openModal();
        }

        async function disconnectWallet() {
            tonConnectUI.disconnect();
        }

        async function walletActionController() {
            if (walletAddress.value) {
                disconnectWallet();
                walletAddress.value = null;
            } else {
                connectWallet();
            }
        }

        expose({
            dashBoardFrame,
            showDashboard,
            hideDashboard
        });

        function waitLoadingFinish(callback) {
            const checkInterval = setInterval(() => {
                if (state.getLoadingState() &&
                    state.getUserInfoFromServer().teleAge) {
                    clearInterval(checkInterval);
                    callback();
                }
            }, 10);
        }

        function showDashboard() {
            userBalance.value = state.getUserInfoFromServer().balance ? state.getUserInfoFromServer().balance : 0;
            userTotalPoints.value = state.getUserInfoFromServer().totalPoints;
            dashBoardFrame.value.classList.remove('hidden');
            setTimeout(() => {
                dashBoardFrame.value.classList.remove('opacity-0');
                setTimeout(() => {
                    state.setDashboardState(true);
                }, 700);
            }, 10);
        }

        function hideDashboard() {
            dashBoardFrame.value.classList.add('opacity-0');
            setTimeout(() => {
                dashBoardFrame.value.classList.add('hidden');
            }, 700);
        }

        function channelClick() {
            state.openLink('https://t.me/defin_ann');
        }

        function groupClick() {
            state.openLink('https://t.me/defin_official');
        }

        function twitterClick() {
            state.openLink('https://x.com/DeFIN_app');
        }

        function websiteClick() {
            state.openLink('https://defin.ai');
        }

        function dataEventListener() {
            document.addEventListener("dataEvent", async (event) => {
                const data = event.detail.message;
                if (data.callback === -1 || data.callback === 1) {
                    const userId = state.getUserInfoFromServer().userId
                    const taskHistory = await definAPI.getHistoryTask(userId);
                    pointHistory.value = taskHistory.data;
                    updateTonBalance();
                }
            });
        }

        async function updateTonBalance() {
            const tonValue = await definAPI.getPredictionUserInfo();
            tonBalance.value = tonValue.data[0].totalTon ? tonValue.data[0].totalTon : 0;
        }

        function updatePointsListener() {
            document.addEventListener("pointsEvent", async (event) => {
                const data = event.detail.message;
                if (data.type === 'add') {
                    setTimeout(() => {
                        userTotalPoints.value = state.getUserInfoFromServer().totalPoints;
                    }, 200);
                    updateTonBalance();
                }
            });
        }

        async function withDraw() {
            if (tonBalance.value < 5) {
                Utils.toastInfo('You need to have at least 5 TON to withdraw!');
                return;
            }
            if (!walletAddress.value) {
                connectWallet();
                return;
            }
            const result = await definAPI.requestWithDraw(tonBalance.value, walletAddress.value);
            if (result?.success) {
                Utils.toastSuccess('Success! You will receive it soon!');
            } else {
                Utils.toastError('An error occurred!');
            }
        }

        onMounted(() => {
            initTonConnect();
            dataEventListener();
            updatePointsListener();
            waitLoadingFinish(() => {
                showDashboard();
            });
        });

        return {
            dashBoardFrame,
            // modules: [Pagination, Autoplay],
            eventData,
            userBalance,
            userTotalPoints,
            pointHistory,
            showDashboard,
            hideDashboard,
            channelClick,
            groupClick,
            twitterClick,
            websiteClick,
            tonBalance,
            withDraw,
            walletAddress,
            walletActionController
        }
    }
}
</script>

<style scoped>
.swiper {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

::v-deep .swiper-pagination-bullet {
    background: #d9d9d97e;
}

::v-deep .swiper-pagination-bullet-active {
    background: #D9D9D9;
}
</style>