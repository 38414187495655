<template>
    <div ref="agesFrame"
        class="absolute top-0 left-0 h-full w-full flex justify-center items-center transition-all duration-700 opacity-0 hidden">
        <img src="../assets/img/ages/background.webp" class="h-full w-full object-cover">
        <div ref="checkingAccountRef"
            class="absolute w-full h-full top-0 left-0 flex flex-col gap-[2%] justify-center items-center transition-all duration-700">
            <h1 ref="titleRef"
                class="font-montserrat text-[#D9D9D9] text-center font-[700] text-[2.5vh] transition-all duration-700 opacity-0">
                Checking your account
            </h1>
            <img ref="loadingRef" src="../assets/img/ages/loading.png"
                class="w-36 h-36 mb-[5%] mt-[3%] transition-all duration-1000 opacity-0">

            <div ref="accountAgeBox"
                class="w-[80%] h-[6%] rounded-xl flex flex-col justify-center items-center bg-[#1b1b1b40] box-shadow border border-gray-500 transition-all duration-1000 opacity-0">
                <div class="w-[90%] h-1/2 flex justify-between items-end">
                    <h1 class="font-montserrat text-[#ffffff] text-center font-[400] text-[1.5vh]">Account Age Verified
                    </h1>
                    <img v-if="accountAgeCheckRef == 1" src="../assets/img/ages/ok.webp" class="h-[60%]">
                    <img v-else-if="accountAgeCheckRef == 0" src="../assets/img/ages/error.webp" class="h-[60%]">
                </div>
                <div class="w-[90%] h-1/2 flex justify-between items-center">
                    <div class="w-full h-[35%] bg-[#686868] rounded-3xl flex justify-start items-start overflow-hidden">
                        <div :style="{ width: accountAgePercent + '%' }"
                            class="h-full bg-[#CDFB2D] transition-all duration-75"></div>
                    </div>
                </div>
            </div>

            <div ref="activityLevelBox"
                class="w-[80%] h-[6%] rounded-xl flex flex-col justify-center items-center bg-[#1b1b1b40] box-shadow border border-gray-500 transition-all duration-1000 opacity-0 ">
                <div class="w-[90%] h-1/2 flex justify-between items-end">
                    <h1 class="font-montserrat text-[#ffffff] text-center font-[400] text-[1.5vh]">Activity Level
                        Analyzed
                    </h1>
                    <img v-if="activityLevelCheckRef == 1" src="../assets/img/ages/ok.webp" class="h-[60%]">
                    <img v-else-if="activityLevelCheckRef == 0" src="../assets/img/ages/error.webp" class="h-[60%]">
                </div>
                <div class="w-[90%] h-1/2 flex justify-between items-center">
                    <div class="w-full h-[35%] bg-[#686868] rounded-3xl flex justify-start items-start overflow-hidden">
                        <div :style="{ width: activityLevelPercent + '%' }"
                            class="h-full bg-[#CDFB2D] transition-all duration-75"></div>
                    </div>
                </div>
            </div>

            <div ref="premiumUserBox"
                class="w-[80%] h-[6%] rounded-xl flex flex-col justify-center items-center bg-[#1b1b1b40] box-shadow border border-gray-500 transition-all duration-1000 opacity-0 ">
                <div class="w-[90%] h-1/2 flex justify-between items-end">
                    <h1 class="font-montserrat text-[#ffffff] text-center font-[400] text-[1.5vh]">Telegram Premium User
                        Checked
                    </h1>
                    <img v-if="premiumUserCheckRef == 1" src="../assets/img/ages/ok.webp" class="h-[60%]">
                    <img v-else-if="premiumUserCheckRef == 0" src="../assets/img/ages/error.webp" class="h-[60%]">
                </div>
                <div class="w-[90%] h-1/2 flex justify-between items-center">
                    <div class="w-full h-[35%] bg-[#686868] rounded-3xl flex justify-start items-start overflow-hidden">
                        <div :style="{ width: premiumUserPercent + '%' }"
                            class="h-full bg-[#CDFB2D] transition-all duration-75"></div>
                    </div>
                </div>
            </div>

            <div ref="ogsStatusBox"
                class="w-[80%] h-[6%] rounded-xl flex flex-col justify-center items-center bg-[#1b1b1b40] box-shadow border border-gray-500 transition-all duration-1000 opacity-0 ">
                <div class="w-[90%] h-1/2 flex justify-between items-end">
                    <h1 class="font-montserrat text-[#ffffff] text-center font-[400] text-[1.5vh]">OGs Status Confirmed
                    </h1>
                    <img v-if="ogsStatusCheckRef == 1" src="../assets/img/ages/ok.webp" class="h-[60%]">
                    <img v-else-if="ogsStatusCheckRef == 0" src="../assets/img/ages/error.webp" class="h-[60%]">
                </div>
                <div class="w-[90%] h-1/2 flex justify-between items-center">
                    <div class="w-full h-[35%] bg-[#686868] rounded-3xl flex justify-start items-start overflow-hidden">
                        <div :style="{ width: ogsStatusPercent + '%' }"
                            class="h-full bg-[#CDFB2D] transition-all duration-75"></div>
                    </div>
                </div>
            </div>

        </div>

        <div ref="resultCheckingAccountRef"
            class="absolute w-full h-full top-0 left-0 flex justify-center items-center transition-all duration-700 opacity-0 hidden">
            <div class="w-[80%] h-[80%] flex flex-col justify-between items-center">
                <div class="w-full h-[15%] flex flex-col justify-start items-center">
                    <div class="w-full h-[7%] flex gap-[3%] justify-center items-center">
                        <div @click="tabToggle(1)" ref="tabYears" class="h-full w-[30%] bg-[#CDFB2D] rounded-2xl"></div>
                        <div @click="tabToggle(2)" ref="tabPoints" class="h-full w-[30%] bg-[#686868] rounded-2xl">
                        </div>
                    </div>
                    <div class="h-[20%]"></div>
                    <h1 ref="title"
                        class="font-montserrat text-[#D9D9D9] text-center font-[700] text-[2.5vh] transition-all duration-700">
                        Rising star!
                    </h1>
                    <span ref="description"
                        class="font-montserrat text-[#D9D9D9] text-center font-[400] text-[1.5vh] transition-all duration-700">You’ve
                        joined
                        Telegram</span>
                </div>
                <div class="w-full h-[25%] flex flex-col justify-start items-center">
                    <h1 ref="valueAgesPoints"
                        class="font-montserrat text-[#D9D9D9] text-center font-[700] text-[12vh] leading-none transition-all duration-700">
                        5.5
                    </h1>
                    <h1 ref="descriptionValueAgesPoints"
                        class="font-montserrat text-[#D9D9D9] text-center font-[700] text-[2.5vh] leading-none transition-all duration-700">
                        years ago
                    </h1>
                </div>
                <div class="w-full h-[15%] flex flex-col justify-between items-center">
                    <span class="font-montserrat text-[#D9D9D9] text-center font-[400] text-[1.5vh]">Your account number
                        #1163687270</span>
                    <div ref="btnCheckingAgePoints" @click="handleBtnCheckingAgeClick"
                        class="w-full h-[43%] rounded-2xl flex justify-center items-center border-white bg-[#CDFB2D] transition-all active:scale-90 box-shadow-btn">
                        <h1 class="font-montserrat text-[#141414] text-center font-[700] text-[2vh]">
                            Continue
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useState } from '../assets/controller/state';
import * as Utils from '../assets/controller/utilities';
import * as definAPI from '../assets/controller/api'

export default {
    name: 'AgesDefin',

    setup() {
        const state = useState();
        const agesFrame = ref(null);
        const titleRef = ref(null);
        const loadingRef = ref(null);
        const accountAgeBox = ref(null);
        const activityLevelBox = ref(null);
        const premiumUserBox = ref(null);
        const ogsStatusBox = ref(null);
        const accountAgeCheckRef = ref(-1);
        const activityLevelCheckRef = ref(-1);
        const premiumUserCheckRef = ref(-1);
        const ogsStatusCheckRef = ref(-1);
        const accountAgePercent = ref(0);
        const activityLevelPercent = ref(0);
        const premiumUserPercent = ref(0);
        const ogsStatusPercent = ref(0);
        const checkingAccountRef = ref(null);
        const resultCheckingAccountRef = ref(null);
        const tabYears = ref(null);
        const tabPoints = ref(null);
        const title = ref(null);
        const description = ref(null);
        const valueAgesPoints = ref(null);
        const descriptionValueAgesPoints = ref(null);
        const btnCheckingAgePoints = ref(null);
        const tabIndex = ref(null);
        const years = ref(0);
        const points = ref(0);
        const checkingAcountData = ref(null);
        const { proxy } = getCurrentInstance();

        function waitRegistersFinish(callback) {
            const checkInterval = setInterval(() => {
                if (state.getLoadingState() && !state.getUserInfoFromServer().teleAge) {
                    clearInterval(checkInterval);
                    callback();
                }
            }, 10);
        }

        async function showAgesAccount() {

            checkingAcountData.value = await definAPI.submitCheckingAccount(state.getUserInfoFromServer().userId, state.getUserInfoFromTelegram().id);
            if (checkingAcountData.value.telePremium.success && checkingAcountData.value.teleCheckingAge.success) {
                years.value = Number(checkingAcountData.value.teleCheckingAge.data.age);
                points.value = checkingAcountData.value.telePremium.data.point + checkingAcountData.value.teleCheckingAge.data.point;
                Utils.dataDispatchEvent({ callback: 1 });
            } else {
                console.log('error')
            }

            agesFrame.value.classList.remove('hidden');
            setTimeout(() => {
                agesFrame.value.classList.remove('opacity-0');
            }, 10);
            setTimeout(() => {
                titleRef.value.classList.remove('opacity-0');
                setTimeout(() => {
                    loadingRef.value.classList.remove('opacity-0');
                    setTimeout(() => {
                        accountAgeBox.value.classList.remove('opacity-0');
                        checkAccountAge();
                    }, 500);
                }, 1000);
            }, 700);
        }

        function checkAccountAge() {
            const checkAccountAgeInterval = setInterval(() => {
                if (accountAgePercent.value == 100) {
                    clearInterval(checkAccountAgeInterval);
                    accountAgeCheckRef.value = 1;
                    activityLevelBox.value.classList.remove('opacity-0');
                    checkActivityLevel();
                }
                accountAgePercent.value++;
            }, 30);
        }

        function checkActivityLevel() {
            const checkActivityLevelInterval = setInterval(() => {
                if (activityLevelPercent.value == 100) {
                    clearInterval(checkActivityLevelInterval);
                    activityLevelCheckRef.value = 1;
                    premiumUserBox.value.classList.remove('opacity-0');
                    checkPremiumUser();
                }
                activityLevelPercent.value++;
            }, 30);
        }

        function checkPremiumUser() {
            const checkPremiumUserInterval = setInterval(() => {
                if (premiumUserPercent.value == 100) {
                    clearInterval(checkPremiumUserInterval);
                    premiumUserCheckRef.value = checkingAcountData.value.telePremium.data.isPremium ? 1 : 0;
                    ogsStatusBox.value.classList.remove('opacity-0');
                    checkOgsStatus();
                }
                premiumUserPercent.value++;
            }, 30);
        }

        function checkOgsStatus() {
            const checkOgsStatusInterval = setInterval(() => {
                if (ogsStatusPercent.value == 100) {
                    clearInterval(checkOgsStatusInterval);
                    ogsStatusCheckRef.value = 0;
                    setTimeout(() => {
                        showResultChecking();
                    }, 2000);
                }
                ogsStatusPercent.value++;
            }, 30);
        }

        function showResultChecking() {
            checkingAccountRef.value.classList.add('opacity-0');
            setTimeout(() => {
                checkingAccountRef.value.classList.add('hidden');
            }, 700);
            setTimeout(() => {
                resultCheckingAccountRef.value.classList.remove('hidden');
                setTimeout(() => {
                    resultCheckingAccountRef.value.classList.remove('opacity-0');
                    valueAgesPoints.value.textContent = years.value;
                    proxy.$confetti.start({
                        defaultDropRate: 5,
                        defaultSize: 5,
                    });
                    setTimeout(() => {
                        proxy.$confetti.stop();
                    }, 3000);
                }, 10);
            }, 500);
        }

        function tabToggle(tab) {
            if (tab == tabIndex.value) {
                return;
            }
            const btnText = btnCheckingAgePoints.value.querySelector('h1');
            if (tab == 1) {
                tabIndex.value = 1;
                tabYears.value.classList.remove('bg-[#686868]')
                tabYears.value.classList.add('bg-[#CDFB2D]')
                tabPoints.value.classList.remove('bg-[#CDFB2D]')
                tabPoints.value.classList.add('bg-[#686868]')
                title.value.classList.add('opacity-0');
                description.value.classList.add('opacity-0');
                valueAgesPoints.value.classList.add('opacity-0');
                descriptionValueAgesPoints.value.classList.add('opacity-0');
                btnText.textContent = 'Continue';
                setTimeout(() => {
                    title.value.textContent = 'Rising star!'
                    title.value.classList.remove('opacity-0');
                    description.value.textContent = 'You’ve joined Telegram'
                    description.value.classList.remove('opacity-0');
                    valueAgesPoints.value.textContent = years.value;
                    valueAgesPoints.value.classList.remove('opacity-0');
                    descriptionValueAgesPoints.value.textContent = 'years ago';
                    descriptionValueAgesPoints.value.classList.remove('opacity-0');
                }, 700);
            }
            if (tab == 2) {
                tabIndex.value = 2;
                tabPoints.value.classList.remove('bg-[#686868]')
                tabPoints.value.classList.add('bg-[#CDFB2D]')
                tabYears.value.classList.remove('bg-[#CDFB2D]')
                tabYears.value.classList.add('bg-[#686868]')
                title.value.classList.add('opacity-0');
                description.value.classList.add('opacity-0');
                valueAgesPoints.value.classList.add('opacity-0');
                descriptionValueAgesPoints.value.classList.add('opacity-0');
                btnText.textContent = 'Get more point';
                setTimeout(() => {
                    title.value.textContent = 'You such an OG!'
                    title.value.classList.remove('opacity-0');
                    description.value.textContent = 'Hear your point reward'
                    description.value.classList.remove('opacity-0');
                    valueAgesPoints.value.textContent = points.value;
                    valueAgesPoints.value.classList.remove('opacity-0');
                    descriptionValueAgesPoints.value.textContent = 'points';
                    descriptionValueAgesPoints.value.classList.remove('opacity-0');
                }, 700);
            }
        }

        async function handleBtnCheckingAgeClick() {
            const btnText = btnCheckingAgePoints.value.querySelector('h1');
            if (btnText.textContent.trim() == 'Continue') {
                tabToggle(2);
            } else {
                if (!state.getUserInfoFromServer().teleAge && !checkingAcountData.value) {
                    Utils.toastError('Something is wrong!');
                    return;
                }
                agesFrame.value.classList.add('opacity-0');
                setTimeout(() => {
                    const userInfoFromServer = state.getUserInfoFromServer();
                    userInfoFromServer.teleAge = years.value;
                    state.setUserInfoFromServer(userInfoFromServer);
                    Utils.updatePointsDispatchEvent({ type: 'add', value: points.value });
                    Utils.dataDispatchEvent({ callback: -1 });
                    agesFrame.value.classList.add('hidden');
                }, 700);
            }
        }


        onMounted(() => {
            waitRegistersFinish(() => {
                showAgesAccount();
            });
        });

        return {
            agesFrame,
            titleRef,
            loadingRef,
            accountAgeBox,
            activityLevelBox,
            premiumUserBox,
            ogsStatusBox,
            accountAgeCheckRef,
            activityLevelCheckRef,
            premiumUserCheckRef,
            ogsStatusCheckRef,
            accountAgePercent,
            activityLevelPercent,
            premiumUserPercent,
            ogsStatusPercent,
            checkingAccountRef,
            resultCheckingAccountRef,
            tabYears,
            tabPoints,
            title,
            description,
            valueAgesPoints,
            descriptionValueAgesPoints,
            btnCheckingAgePoints,
            handleBtnCheckingAgeClick,
            tabIndex,
            tabToggle,
            checkingAcountData
        }
    }
}
</script>

<style scoped>
.box-shadow {
    box-shadow: 0px 12px 44px 0px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(22px);
}

.box-shadow-btn {
    box-shadow: 0px 0px 15px 0px #CDFB2D;
}
</style>