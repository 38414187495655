import { useToast } from "vue-toastification";
import Odometer from 'odometer';
import 'odometer/themes/odometer-theme-default.css';


const toast = useToast();
const DEFINKEY = 'jwt';

export function setDefinKeyToLocalStorage(value) {
  localStorage.setItem(DEFINKEY, value);
}
export function getDefinKeyFromLocalStorage() {
  return localStorage.getItem(DEFINKEY) || null;
}
export function removeDefinKeyToLocalStorage() {
  return localStorage.removeItem(DEFINKEY);
}

export function menuDispatchEvent(data) {
  const customEvent = new CustomEvent("menuEvent", {
    detail: { message: data }
  });
  document.dispatchEvent(customEvent);
}

export function menuResponseDispatchEvent(data) {
  const customEvent = new CustomEvent("menuEventResponse", {
    detail: { message: data }
  });
  document.dispatchEvent(customEvent);
}

export function updatePointsDispatchEvent(data) {
  const customEvent = new CustomEvent("pointsEvent", {
    detail: { message: data }
  });
  document.dispatchEvent(customEvent);
}

export function dataDispatchEvent(data) {
  const customEvent = new CustomEvent("dataEvent", {
    detail: { message: data }
  });
  document.dispatchEvent(customEvent);
}

export function toastSuccess(content) {
  toast.success(content, {
    timeout: 2000,
    toastClassName: "toast"
  });
}

export function toastError(content) {
  toast.error(content, {
    timeout: 2000,
    toastClassName: "toast"
  });
}

export function toastInfo(content) {
  toast.info(content, {
    timeout: 2000,
    toastClassName: "toast"
  });
}

export function copyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    toastSuccess('Copying ref link was ' + msg);
  } catch (err) {
    toastError('Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function initOdometer(el, value) {
  return new Odometer({
    el,
    value,
    format: '(,ddd).dd'
  });
}

export function updateOdometer(odometerInstance, value) {
  odometerInstance.update(value);
}

export function formatNumber(number) {
  return new Intl.NumberFormat('en-US').format(number);
}
