import { io } from 'socket.io-client';
import { getDefinKeyFromLocalStorage } from './utilities'

let socket;
const ORIGINAL_PATH_URL = String(process.env.VUE_APP_SOCKET_URL);

const socketConnect = () => {
    socket = io(ORIGINAL_PATH_URL, {
        path: '/service/socket.io',
        query: {
            token: getDefinKeyFromLocalStorage()
        }
    });
    return socket;
};

export default socketConnect;