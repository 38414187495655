<template>
    <div ref="predictionFrame"
        class="absolute top-0 left-0 h-full w-full flex justify-center items-center transition-all duration-700 z-[10] opacity-0 hidden">
        <img src="../assets/img/background-main.png" class="h-full w-full object-cover">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-start">
            <div
                class="w-full h-[90%] flex flex-col justify-start items-start overflow-y-auto overflow-x-hidden no-scrollbar">
                <div class="relative w-full h-auto">
                    <img src="../assets/img/prediction/defin-game-card-header.webp" class="w-full h-auto">
                    <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                        <div
                            class="h-[80%] aspect-square rounded-full bg-black flex justify-center items-center gap-[5%]">
                            <div class="h-[35%] aspect-square flex justify-center items-center hidden">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">{{
                                    timeMinutes }}</h1>
                            </div>
                            <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none hidden">:</h1>
                            <div class="h-[70%] aspect-square flex justify-center items-center">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[5vh] font-[700] leading-none">{{
                                    timeSeconds }}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="absolute top-0 left-0 w-full h-full flex justify-center items-start gap-[35%]">
                        <div class="h-[70%] w-[25%] flex justify-center items-center gap-[5%]">
                            <img src="../assets/img/prediction/defingame-ticket-lg.webp" class="h-[5vh]">
                            <h1 ref="ticketRemaining"
                                class="font-montserrat text-[#141414] text-[4vh] font-[700] leading-none">{{
                                    freePredictionLeft }}</h1>
                        </div>
                        <div class="h-[70%] w-[25%] flex justify-center items-center gap-[6%]">
                            <h1 ref="totalTONwon"
                                class="font-montserrat text-[#141414] text-[4vh] font-[700] leading-none">{{
                                    totalTONWon }}</h1>
                            <img src="../assets/img/prediction/defin-game-icon-ton.webp" class="h-[4vh]">
                        </div>
                    </div>
                </div>
                <div class="relative w-full h-auto flex justify-center -translate-y-[25%]">
                    <img src="../assets/img/prediction/defin-game-card-prediction-mode.webp" class="w-[95%] h-auto">
                    <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center gap-[30%]">
                        <div ref="freeTicketBtn" @click="gamePlayToggle(1)"
                            class="w-[30%] h-[60%] border border-[#ffffff4d] rounded-full flex justify-center items-center bg-[#CDFB2D] transition-all duration-300 active:scale-90">
                            <h1
                                class="font-montserrat text-[#141414] text-[2vh] font-[700] leading-none transition-all duration-300">
                                Free Ticket
                            </h1>
                        </div>
                        <div ref="vDEFTicketBtn" @click="gamePlayToggle(2)"
                            class="w-[30%] h-[60%] border border-[#ffffff4d] rounded-full flex justify-center items-center transition-all duration-300 active:scale-90">
                            <h1
                                class="font-montserrat text-[#ffffff4d] text-[2vh] font-[700] leading-none transition-all duration-300">
                                $vDEF
                            </h1>
                        </div>
                    </div>
                </div>
                <div ref="gamePlay" class="relative w-full h-[35vh] min-h-[35vh] flex transition-all duration-300">
                    <div ref="freeTicketGameplay"
                        class="absolute top-0 left-0 w-full min-w-full h-[35vh] min-h-[35vh] flex justify-center overflow-hidden transition-all duration-300">
                        <div class="w-[95%] h-full rounded-3xl border border-[#ffffff4d] flex flex-col">
                            <div class="w-full h-[7%] flex justify-between items-center mt-[5%]">
                                <img src="../assets/img/prediction/defin-game-icon-live.webp"
                                    class="h-[80%] w-auto ml-[5%]">
                                <h1 ref="lockPriceFreeGamePlay"
                                    class="font-montserrat text-[#ffffff4d] text-[1.8vh] font-[400] leading-none mr-[5%] transition-all duration-300 opacity-0">
                                    Lock:
                                    <span class="font-[700] text-[#D9D9D9]">
                                        ${{ lockPriceFreeGamePlayCurrent }}
                                    </span>
                                </h1>
                            </div>
                            <div class="w-full h-auto flex justify-center mt-[3%] mb-[5%]">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">BTC Price
                                </h1>
                            </div>
                            <div class="w-full h-auto flex justify-center mb-[5%]">
                                <h1 ref="btcPriceFreeGamePlay"
                                    class="font-Helvetica text-[#00CE3A] text-[5vh] font-[700] leading-none">
                                    $<span>0</span>
                                </h1>
                            </div>
                            <div class="w-full h-[20%] flex justify-center">
                                <div ref="freeGameplayBtnContainer" class="w-[90%] h-full flex justify-between">
                                    <div ref="gainFreeGameplayBtn" @click="freeGamePlayPrediction(1)"
                                        class="w-[48%] h-full border border-[#ffffff4d] rounded-md bg-gradient-to-b from-[#90DF75] to-[#62B655] flex justify-center items-center box-shadow-gain transition-all duration-300">
                                        <h1 class="font-montserrat text-[#141414] text-[2vh] font-[700] leading-none">
                                            Gain
                                        </h1>
                                    </div>
                                    <div ref="dropFreeGameplayBtn" @click="freeGamePlayPrediction(2)"
                                        class="w-[48%] h-full border border-[#ffffff4d] rounded-md bg-gradient-to-b from-[#FF7F7E] to-[#FE1F1E] flex justify-center items-center box-shadow-drop transition-all duration-300">
                                        <h1 class="font-montserrat text-[#141414] text-[2vh] font-[700] leading-none">
                                            Drop
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full h-[26%] flex justify-center items-center">
                                <h1 class="font-montserrat text-[#ffffff4d] text-[1.8vh] font-[400] leading-none">
                                    You will won <span class="text-[#D9D9D9] font-[700]">+10 $vDEF</span> after time
                                    end
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div ref="vDEFTicketGameplay"
                        class="absolute top-0 left-0 w-full min-w-full h-[35vh] min-h-[35vh] flex justify-center overflow-hidden transition-all duration-300 translate-x-[100%]">
                        <div ref="vDEFTicket"
                            class="w-[95%] h-full rounded-3xl border border-[#ffffff4d] flex flex-col transition-all duration-300">
                            <div class="w-full h-[7%] flex justify-between items-center mt-[5%]">
                                <img src="../assets/img/prediction/defin-game-icon-live.webp"
                                    class="h-[80%] w-auto ml-[5%]">
                                <h1 ref="lockPriceVDEFGamePlay"
                                    class="font-montserrat text-[#ffffff4d] text-[1.8vh] font-[400] leading-none mr-[5%] transition-all duration-300 opacity-0">
                                    Lock:
                                    <span class="font-[700] text-[#D9D9D9]">
                                        ${{ lockPriceVDEFGamePlayCurrent }}
                                    </span>
                                </h1>
                            </div>
                            <div class="w-full h-auto flex justify-center mt-[3%] mb-[5%]">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">BTC Price
                                </h1>
                            </div>
                            <div class="w-full h-auto flex justify-center mb-[5%]">
                                <h1 ref="btcPricevDEFGamePlay"
                                    class="font-montserrat text-[#00CE3A] text-[5vh] font-[700] leading-none">
                                    $<span>0</span>
                                </h1>
                            </div>
                            <div class="w-full h-[20%] flex justify-center">
                                <div ref="enterVDEFContainer" class="w-[90%] h-full flex justify-between">
                                    <div ref="enterGainBtn" @click="showVDEFPredictionPopup(1)"
                                        class="w-[48%] h-full border border-[#ffffff4d] rounded-md bg-gradient-to-b from-[#90DF75] to-[#62B655] flex justify-center items-center box-shadow-gain">
                                        <h1 class="font-montserrat text-[#141414] text-[2vh] font-[700] leading-none">
                                            Enter Gain
                                        </h1>
                                    </div>
                                    <div ref="enterDropBtn" @click="showVDEFPredictionPopup(2)"
                                        class="w-[48%] h-full border border-[#ffffff4d] rounded-md bg-gradient-to-b from-[#FF7F7E] to-[#FE1F1E] flex justify-center items-center box-shadow-drop">
                                        <h1 class="font-montserrat text-[#141414] text-[2vh] font-[700] leading-none">
                                            Enter Drop
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full h-[26%] flex justify-center items-center">
                                <h1 class="font-montserrat text-[#ffffff4d] text-[1.8vh] font-[400] leading-none">
                                    You will won the <span class="text-[#D9D9D9] font-[700]">x{{ vDEFMultiplier }}
                                        $vDEF</span> you bet
                                </h1>
                            </div>
                        </div>



                        <div ref="enterVDEFPredictionPopup"
                            class="absolute top-0 left-0 h-full w-full flex justify-center transition-all duration-300 opacity-0 hidden">
                            <div class="w-[95%] h-full rounded-3xl border border-[#ffffff4d] flex flex-col">
                                <div class="w-full h-[7%] flex justify-between items-center mt-[5%]">
                                    <img src="../assets/img/prediction/defingame-btcpride-stt-lg.png"
                                        class="h-full w-auto ml-[5%]">
                                    <h1
                                        class="font-montserrat text-[#ffffff4d] text-[1.8vh] font-[400] leading-none mr-[5%] transition-all duration-300">
                                        Lock:
                                        <span class="font-[700] text-[#D9D9D9]">
                                            ${{ lockPriceVDEFGamePlayCurrent }}
                                        </span>
                                    </h1>
                                </div>
                                <h1
                                    class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none ml-[5.5%] mt-[5%] mb-[3%]">
                                    Set vDEF prediction
                                </h1>
                                <div class="w-full h-[20%] flex justify-center items-center">
                                    <input ref="inputVDEF" v-model="inputVDEFValue" @input="validateDecimalInput"
                                        type="text" inputmode="decimal" placeholder="0"
                                        class="w-[90%] h-full rounded-xl border-2 border-[#ffffff4d] 
                                    outline-none bg-gradient-to-b from-[#90DF75] to-[#62B655] pl-[2%] pr-[2%]
                                    font-montserrat text-[2.5vh] font-[700] text-[#141414] leading-none box-shadow-gain mb-[3%]">
                                </div>
                                <div class="w-full h-[10%] flex justify-between items-center">
                                    <h1
                                        class="font-montserrat text-[#D9D9D9] text-[2vh] font-[400] leading-none ml-[5.5%]">
                                        Balance
                                    </h1>
                                    <div class="w-auto h-full flex items-center mr-[5.5%] gap-1">
                                        <img src="../assets/img/xDefin.webp" class="h-[80%] w-auto">
                                        <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                            {{ formatNumber(yourBalance) }}
                                        </h1>
                                    </div>
                                </div>
                                <div class="w-full h-[10%] flex justify-between items-center">
                                    <h1
                                        class="font-montserrat text-[#D9D9D9] text-[2vh] font-[400] leading-none ml-[5.5%]">
                                        Prize pool
                                    </h1>
                                    <div class="w-auto h-full flex items-center mr-[5.5%] gap-1">
                                        <img src="../assets/img/xDefin.webp" class="h-[80%] w-auto">
                                        <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                            {{ formatNumber(prizePool) }}
                                        </h1>
                                    </div>
                                </div>
                                <div class="w-full h-[15%] flex justify-center mt-[3%]">
                                    <div class="w-[90%] h-full flex justify-between">
                                        <div @click="confirmVEDFPrediction"
                                            class="w-[48%] h-full border-2 border-[#686868] rounded-md flex justify-center items-center bg-[#D9D9D9] transition-all duration-300 active:scale-90">
                                            <h1
                                                class="font-montserrat text-[#141414] text-[2vh] font-[700] leading-none">
                                                Confirm
                                            </h1>
                                        </div>
                                        <div @click="cancelVDEFPrediction"
                                            class="w-[48%] h-full border-2 border-[#ffffff3f] rounded-md flex justify-center items-center bg-[#141414] transition-all duration-300 active:scale-90">
                                            <h1
                                                class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                                Back
                                            </h1>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div ref="gamePlayResult"
                    class="relative w-full h-[35vh] min-h-[35vh] flex justify-center transition-all duration-300 opacity-0 hidden">
                    <div v-if="winPrediction" class="w-[95%] h-full flex flex-col justify-between items-center">
                        <div class="relative w-1/2 h-auto flex justify-center">
                            <img src="../assets/img/prediction/defin-game-winprize.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                <img src="../assets/img/prediction/defin-game-win-anim.png" class="w-full scale-125">
                            </div>
                        </div>
                        <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                            You won +{{ prizeWonValue }} $vDEF
                        </h1>
                        <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                            Win streak +1
                        </h1>
                        <div @click="hideGamePlayResult"
                            class="w-full h-[15%] border border-[#CDFB2D] rounded-full flex justify-center items-center transition-all duration-300 active:scale-90 z-10">
                            <h1 class="font-montserrat text-[#CDFB2D] text-[2vh] font-[700] leading-none">
                                Go on!
                            </h1>
                        </div>
                    </div>
                    <div v-else class="w-[95%] h-full flex flex-col justify-between items-center">
                        <div class="relative w-2/5 h-auto flex justify-center">
                            <img src="../assets/img/prediction/defin-game-lose-anim.png" class="w-full">
                        </div>
                        <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                            Sorry, you miss the prize!
                        </h1>
                        <div @click="hideGamePlayResult"
                            class="w-full h-[15%] border border-[#CDFB2D] rounded-full flex justify-center items-center transition-all duration-300 active:scale-90">
                            <h1 class="font-montserrat text-[#CDFB2D] text-[2vh] font-[700] leading-none">
                                Give it a try!
                            </h1>
                        </div>
                    </div>
                </div>

                <div v-if="typeGamePlay === 1" class="w-full h-[13vh] flex justify-center min-h-[13vh]">
                    <div class="w-[95%] h-full flex flex-col gap-[10%] justify-center items-center">
                        <div class="h-[35%] w-full flex justify-between items-center">
                            <div
                                class="h-full w-auto flex justify-center items-center border border-[#ffffff4d] rounded-full pl-[3%] pr-[3%]">
                                <h1 class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                    Win Streak {{ winStreak }}/10
                                </h1>
                            </div>
                            <div class="h-full w-auto flex justify-center items-center gap-2">
                                <img src="../assets/img/prediction/defin-game-star-on.webp" class="w-[25px] h-auto"
                                    :class="{ 'grayscale-[100%]': winStreak < 3 }" />
                                <img src="../assets/img/prediction/defin-game-star-on.webp" class="w-[25px] h-auto"
                                    :class="{ 'grayscale-[100%]': winStreak < 5 }" />
                                <img src="../assets/img/prediction/defin-game-star-on.webp" class="w-[25px] h-auto"
                                    :class="{ 'grayscale-[100%]': winStreak < 10 }" />
                            </div>
                            <div
                                class="h-full w-auto flex justify-center items-center border border-[#ffffff4d] rounded-full pl-[3%] pr-[3%] gap-2">
                                <h1 class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                    Bonus {{ getBonusTON() }}
                                </h1>
                                <img src="../assets/img/defingame-ton-lg.webp" class="w-[18px] h-auto">
                            </div>
                        </div>
                        <h1 class="font-montserrat text-[#CDFB2D] text-[1.4vh] font-[400] leading-none">
                            Big Streaks, Big Rewards – Up to 1 TON!
                        </h1>
                    </div>
                </div>

                <div :class="typeGamePlay === 2 ? 'mt-[3%]' : ''" class="w-full h-auto flex justify-center mb-[10%]">
                    <div class="w-[95%] h-auto border border-[#ffffff4d] rounded-3xl flex flex-col pb-[5%]">
                        <div class="w-full aspect-[5/1] flex justify-center mb-[5%]">
                            <div class="w-[90%] h-full flex justify-center items-center gap-[10%]">
                                <div ref="historyBtn" @click="predictionRankingToggle(1)"
                                    class="w-2/5 h-1/2 border border-[#CDFB2D] rounded-full flex justify-center items-center">
                                    <h1 class="font-montserrat text-[#CDFB2D] text-[2vh] font-[700] leading-none">
                                        History
                                    </h1>
                                </div>
                                <div ref="leaderboardBtn" @click="predictionRankingToggle(2)"
                                    class="w-2/5 h-1/2 border-[#CDFB2D] rounded-full flex justify-center items-center">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                        Leaderboard
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div ref="history"
                            class="w-full h-auto flex flex-col items-center justify-center transition-all duration-300">
                            <div v-for="history in historyData" :key="history"
                                class="w-[90%] aspect-[6/1] flex border-b border-[#ffffff4d] mb-[5%]">
                                <div class="h-full flex-1 flex flex-col gap-[30%]">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                        Entered
                                    </h1>
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[700] leading-none">
                                        {{ history.pointsWagered !== 0 ? history.pointsWagered + ' $vDEF' : 'Free' }}
                                    </h1>
                                </div>
                                <div class="h-full flex-1 flex flex-col items-center gap-[30%] -translate-x-[10%]">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                        Predict
                                    </h1>
                                    <h1 class="font-montserrat text-[2vh] font-[700] leading-none uppercase"
                                        :class="{ 'text-[#00CE3A]': history.predicted === 'up', 'text-[#FF0000]': history.predicted === 'down' }">
                                        {{ history.predicted }}
                                    </h1>
                                </div>
                                <div class="h-full flex-1 flex flex-col items-center gap-[30%] translate-x-[10%]">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                        Result
                                    </h1>
                                    <h1 class="font-montserrat text-[2vh] font-[700] leading-none"
                                        :class="{ 'text-[#FF0000]': !history.isCorrect, 'text-[#00CE3A]': history.isCorrect }">
                                        {{ !history.isCorrect ? 'LOSE' : 'WIN' }}
                                    </h1>
                                </div>
                                <div class="h-full flex-1 flex flex-col items-end gap-[30%]">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                        Status
                                    </h1>
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[700] leading-none">
                                        {{ history.status === 'completed' ? 'Done' : 'Error' }}
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div ref="leaderboard"
                            class="w-full h-auto flex flex-col items-center justify-center transition-all duration-300 opacity-0 hidden">
                            <div class="w-[90%] aspect-[6/1] flex justify-between items-center">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                    Your rank <span class="font-bold">#{{ userRankData?.rank }}</span>
                                </h1>
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[400] leading-none">
                                    |
                                </h1>
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[400] leading-none">
                                    {{ userRankData?.teleAccount }}
                                </h1>
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[400] leading-none">
                                    |
                                </h1>
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[700] leading-none">
                                    {{ userRankData?.wins === null ? 0 : userRankData?.wins }} <span
                                        class="text-[#00CE3A]">WIN</span>
                                </h1>
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[400] leading-none">
                                    |
                                </h1>
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[700] leading-none">
                                    {{ userRankData?.losses === null ? 0 : userRankData?.losses }} <span
                                        class="text-[#FF0000]">LOSE</span>
                                </h1>
                            </div>
                            <div class="w-[90%] aspect-[10/1] flex items-center border-b border-[#ffffff4d] mb-[2%]">
                                <div class="flex-1 h-full flex justify-center items-center">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                        Rank
                                    </h1>
                                </div>
                                <div style="flex: 3;" class="h-full flex justify-start items-center">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                        Name
                                    </h1>
                                </div>
                                <div class="flex-1 h-full flex justify-center items-center">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                        Win
                                    </h1>
                                </div>
                                <div class="flex-1 h-full flex justify-center items-center">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.4vh] font-[400] leading-none">
                                        Lose
                                    </h1>
                                </div>
                            </div>
                            <div v-for="rank in leaderboardData" :key="rank"
                                class="w-[90%] aspect-[15/1] flex items-center">
                                <div class="flex-1 h-full flex justify-center items-center">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                        #{{ rank.rank }}
                                    </h1>
                                </div>
                                <div style="flex: 3;" class="h-full flex justify-start items-center">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[400] leading-none">
                                        {{ rank.teleAccount }}
                                    </h1>
                                </div>
                                <div class="flex-1 h-full flex justify-center items-center">
                                    <h1
                                        class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none ml-[5%]">
                                        {{ rank.wins === null ? 0 : rank.wins }}
                                    </h1>
                                </div>
                                <div class="flex-1 h-full flex justify-center items-center">
                                    <h1
                                        class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none ml-[5%]">
                                        {{ rank.losses === null ? 0 : rank.losses }}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useState } from '../assets/controller/state';
import * as definAPI from '../assets/controller/api'
import * as Utils from '../assets/controller/utilities';
import socketConnect from '../assets/controller/socket'

export default {
    name: 'PredictionDefin',
    components: {

    },
    setup(props, { expose }) {
        const state = useState();
        let socket;
        const predictionFrame = ref(null);
        const timeMinutes = ref('00');
        const timeSeconds = ref('30');
        const ticketRemaining = ref(null);
        const totalTONwon = ref(null);
        const freeTicketGameplay = ref(null);
        const vDEFTicketGameplay = ref(null);
        const freeTicketBtn = ref(null);
        const vDEFTicketBtn = ref(null);
        const gamePlay = ref(null);
        const lockPriceFreeGamePlay = ref(null);
        const gainFreeGameplayBtn = ref(null);
        const dropFreeGameplayBtn = ref(null);
        const freeGameplayBtnContainer = ref(null);
        const lockPriceFreeGamePlayCurrent = ref('0');
        const gamePlayResult = ref(null);
        const winPrediction = ref(false);
        const prizeWonValue = ref(1000);
        const freePredictionLeft = ref(0);
        const totalTONWon = ref(0);
        const btcPriceFreeGamePlay = ref(null);
        const freeBtnPriceInstance = ref(null);
        const currentBtcPrice = ref(66666.66);
        const btcPricevDEFGamePlay = ref(null);
        const vDEFBtnPriceInstance = ref(null);
        const isPredictionRunning = ref(false);
        const lockPriceVDEFGamePlay = ref(null);
        const lockPriceVDEFGamePlayCurrent = ref('0');
        const enterGainBtn = ref(null);
        const enterDropBtn = ref(null);
        const vDEFMultiplier = ref(1.98);
        const yourBalance = ref(0);
        const prizePool = ref(8900000);
        const enterVDEFPredictionPopup = ref(null);
        const inputVDEF = ref(null);
        const vDEFTicket = ref(null);
        const vDEFGamePlayCurrentSelectIndex = ref(1);
        const enterVDEFContainer = ref(null);
        const inputVDEFValue = ref(null);
        const typeGamePlay = ref(1);
        const btcPriceDisplay = ref(66666.66);
        const winStreak = ref(1);
        const historyBtn = ref(null);
        const leaderboardBtn = ref(null);
        const history = ref(null);
        const leaderboard = ref(null);
        const historyData = ref([]);
        const leaderboardData = ref([]);
        const userRankData = ref({});
        const serverConfirmPredictAction = ref(false);

        function gamePlayToggle(type) {
            if (isPredictionRunning.value) {
                return;
            }
            if (type === 1) {
                if (freePredictionLeft.value <= 0) {
                    Utils.toastError('You are out of free tickets!');
                    return;
                }
                freeTicketGameplay.value.classList.remove('-translate-x-[100%]')
                vDEFTicketGameplay.value.classList.add('translate-x-[100%]')
                freeTicketBtn.value.classList.add('bg-[#CDFB2D]');
                freeTicketBtn.value.querySelector('h1').classList.remove('text-[#ffffff4d]');
                freeTicketBtn.value.querySelector('h1').classList.add('text-[#141414]');
                vDEFTicketBtn.value.classList.remove('bg-[#CDFB2D]');
                vDEFTicketBtn.value.querySelector('h1').classList.remove('text-[#141414]');
                vDEFTicketBtn.value.querySelector('h1').classList.add('text-[#ffffff4d]');
            }
            if (type === 2) {
                freeTicketGameplay.value.classList.add('-translate-x-[100%]')
                vDEFTicketGameplay.value.classList.remove('translate-x-[100%]')
                freeTicketBtn.value.classList.remove('bg-[#CDFB2D]');
                freeTicketBtn.value.querySelector('h1').classList.remove('text-[#141414]');
                freeTicketBtn.value.querySelector('h1').classList.add('text-[#ffffff4d]');
                vDEFTicketBtn.value.classList.add('bg-[#CDFB2D]');
                vDEFTicketBtn.value.querySelector('h1').classList.remove('text-[#ffffff4d]');
                vDEFTicketBtn.value.querySelector('h1').classList.add('text-[#141414]');
            }
            typeGamePlay.value = type;
        }

        function startCountdown(minutes, seconds, callback) {
            let totalSeconds = minutes * 60 + seconds;

            const countdownInterval = setInterval(() => {
                let mins = Math.floor(totalSeconds / 60);
                let secs = totalSeconds % 60;

                mins = mins < 10 ? '0' + mins : mins;
                secs = secs < 10 ? '0' + secs : secs;

                timeMinutes.value = mins;
                timeSeconds.value = secs;

                totalSeconds--;

                if (totalSeconds < 0) {
                    timeMinutes.value = '00';
                    timeSeconds.value = '30';
                    clearInterval(countdownInterval);
                    callback();
                }
            }, 1000);
        }

        function freeGamePlayPrediction(type) {
            if (isPredictionRunning.value) {
                Utils.toastError('Prediction is already running.');
                return;
            }
            if (!freePredictionLeft.value) {
                Utils.toastError('You are out of free tickets!');
                return;
            }

            const lockPrice = parseFloat(lockPriceFreeGamePlayCurrent.value.replace(/,/g, ''));
            const direction = type === 1 ? 'up' : 'down';

            predict(direction, lockPrice, 1, 10);

            waitServerConfrimPredict(() => {
                isPredictionRunning.value = true;
                lockPriceFreeGamePlayCurrent.value = Utils.formatNumber(btcPriceDisplay.value.toFixed(2));

                if (type === 1) {
                    gainFreeGameplayBtn.value.classList.add('w-full');
                    dropFreeGameplayBtn.value.classList.add('hidden');
                } else {
                    freeGameplayBtnContainer.value.classList.replace('justify-between', 'justify-end');
                    dropFreeGameplayBtn.value.classList.add('w-full');
                    gainFreeGameplayBtn.value.classList.add('hidden');
                }


                lockPriceFreeGamePlay.value.classList.remove('opacity-0');
                freePredictionLeft.value -= 1;
            });

        }

        function showGamePlayResult(isWon, wonValue = 0) {
            console.log(`Won value ${wonValue}`);
            winPrediction.value = isWon;

            gamePlay.value.classList.add('opacity-0');

            setTimeout(() => {
                gamePlay.value.classList.add('hidden');
                gamePlayResult.value.classList.remove('hidden');

                setTimeout(() => {
                    gamePlayResult.value.classList.remove('opacity-0');
                }, 10);
            }, 300);

            yourBalance.value += wonValue;
            Utils.updatePointsDispatchEvent({ type: 'add', value: wonValue });
            prizeWonValue.value = wonValue;
            fetchAllDataPrediction();
        }


        function hideGamePlayResult() {
            lockPriceFreeGamePlay.value.classList.add('opacity-0');
            gainFreeGameplayBtn.value.classList.remove('w-full');
            dropFreeGameplayBtn.value.classList.remove('hidden');
            dropFreeGameplayBtn.value.classList.remove('w-full');
            gainFreeGameplayBtn.value.classList.remove('hidden');
            freeGameplayBtnContainer.value.classList.remove('justify-end');
            freeGameplayBtnContainer.value.classList.add('justify-between');
            lockPriceVDEFGamePlay.value.classList.add('opacity-0');
            enterDropBtn.value.classList.remove('hidden');
            enterGainBtn.value.classList.remove('w-full');
            enterGainBtn.value.querySelector('h1').innerHTML = 'Enter Gain';
            enterDropBtn.value.classList.remove('w-full');
            enterGainBtn.value.classList.remove('hidden');
            enterDropBtn.value.querySelector('h1').innerHTML = 'Enter Drop';
            enterVDEFContainer.value.classList.replace('justify-end', 'justify-between');
            gamePlayResult.value.classList.add('opacity-0');
            setTimeout(() => {
                gamePlayResult.value.classList.add('hidden');
                gamePlay.value.classList.remove('hidden');
                setTimeout(() => {
                    gamePlay.value.classList.remove('opacity-0');
                    winPrediction.value = false;
                    prizeWonValue.value = 0;
                }, 10);
            }, 300);
        }

        function initOdometer() {
            const freeBtnPriceElement = btcPriceFreeGamePlay.value.querySelector('span');
            const vDEFBtnPriceElement = btcPricevDEFGamePlay.value.querySelector('span');

            freeBtnPriceInstance.value = Utils.initOdometer(freeBtnPriceElement, currentBtcPrice.value);
            vDEFBtnPriceInstance.value = Utils.initOdometer(vDEFBtnPriceElement, currentBtcPrice.value);

            let previousPrice = currentBtcPrice.value;

            setInterval(() => {
                const currentPrice = currentBtcPrice.value;

                let lockPrice = typeGamePlay.value === 1
                    ? parseFloat(lockPriceFreeGamePlayCurrent.value.replace(/,/g, ''))
                    : typeGamePlay.value === 2
                        ? parseFloat(lockPriceVDEFGamePlayCurrent.value.replace(/,/g, ''))
                        : undefined;

                btcPriceDisplay.value = currentPrice;
                if (previousPrice != currentPrice) {
                    Utils.updateOdometer(freeBtnPriceInstance.value, currentPrice);
                    Utils.updateOdometer(vDEFBtnPriceInstance.value, currentPrice);
                }

                const updatePriceClass = (element, condition) => {
                    if (!element) {
                        return;
                    }
                    element.classList.toggle('text-red-600', condition < 0);
                    element.classList.toggle('text-[#00CE3A]', condition >= 0);
                    // element.classList.toggle('text-[#fff]', condition == 0);
                };

                if (isPredictionRunning.value) {
                    const priceComparison = currentPrice - lockPrice;
                    updatePriceClass(btcPriceFreeGamePlay.value, priceComparison);
                    updatePriceClass(btcPricevDEFGamePlay.value, priceComparison);
                } else {
                    const priceDifference = currentPrice - previousPrice;
                    updatePriceClass(btcPriceFreeGamePlay.value, priceDifference);
                    updatePriceClass(btcPricevDEFGamePlay.value, priceDifference);
                }

                previousPrice = currentPrice;
            }, 2000);
        }

        function predict(direction, priceInput, typePredict, points) {
            socket.emit('predict', {
                userId: state.getUserInfoFromServer().userId,
                predicted: direction, price: priceInput, type: typePredict, pointsWagered: points
            });
        }


        function showVDEFPredictionPopup(type) {
            if (isPredictionRunning.value) {
                return;
            }
            vDEFTicket.value.classList.add('opacity-0');
            setTimeout(() => {
                vDEFTicket.value.classList.add('hidden');
            }, 300);
            enterVDEFPredictionPopup.value.classList.remove('hidden');
            lockPriceVDEFGamePlayCurrent.value = Utils.formatNumber(parseFloat(btcPriceDisplay.value.toFixed(2)));
            setTimeout(() => {
                enterVDEFPredictionPopup.value.classList.remove('opacity-0');
            }, 10);

            if (type === 1) {
                inputVDEF.value.classList.remove('from-[#FF7F7E]');
                inputVDEF.value.classList.remove('to-[#FE1F1E]');
                inputVDEF.value.classList.remove('box-shadow-drop');
                inputVDEF.value.classList.add('from-[#90DF75]');
                inputVDEF.value.classList.add('to-[#62B655]');
                inputVDEF.value.classList.add('box-shadow-gain');
            }
            if (type === 2) {
                inputVDEF.value.classList.remove('from-[#90DF75]');
                inputVDEF.value.classList.remove('to-[#62B655]');
                inputVDEF.value.classList.remove('box-shadow-gain');
                inputVDEF.value.classList.add('from-[#FF7F7E]');
                inputVDEF.value.classList.add('to-[#FE1F1E]');
                inputVDEF.value.classList.add('box-shadow-drop');
            }
            vDEFGamePlayCurrentSelectIndex.value = type;
        }

        function cancelVDEFPrediction() {
            hideVDEFPredictionPopup();
        }

        function hideVDEFPredictionPopup() {
            enterVDEFPredictionPopup.value.classList.add('opacity-0');
            setTimeout(() => {
                enterVDEFPredictionPopup.value.classList.add('hidden');
            }, 300);
            vDEFTicket.value.classList.remove('hidden');
            setTimeout(() => {
                vDEFTicket.value.classList.remove('opacity-0');
            }, 10);
        }

        function confirmVEDFPrediction() {
            const vDEFValue = parseFloat(inputVDEFValue.value ? inputVDEFValue.value : 0);
            const lockPrice = parseFloat(lockPriceVDEFGamePlayCurrent.value.replace(/,/g, ''));

            if (vDEFValue <= 0) {
                Utils.toastError('The $vDEF bet number must be greater than 0');
                return;
            }
            if (vDEFValue > yourBalance.value) {
                Utils.toastError('Insufficient balance $vDEF');
                return;
            }

            const direction = vDEFGamePlayCurrentSelectIndex.value === 1 ? 'up' : 'down';
            predict(direction, lockPrice, 2, vDEFValue);

            waitServerConfrimPredict(() => {
                lockPriceVDEFGamePlay.value.classList.remove('opacity-0');
                hideVDEFPredictionPopup();
                isPredictionRunning.value = true;

                if (direction === 'up') {
                    enterDropBtn.value.classList.add('hidden');
                    enterGainBtn.value.classList.add('w-full');
                    enterGainBtn.value.querySelector('h1').innerHTML = 'Gain Entered';
                } else {
                    enterVDEFContainer.value.classList.replace('justify-between', 'justify-end');
                    enterDropBtn.value.classList.add('w-full');
                    enterGainBtn.value.classList.add('hidden');
                    enterDropBtn.value.querySelector('h1').innerHTML = 'Drop Entered';
                }
            });
        }

        function validateDecimalInput() {
            const decimalRegex = /^\d*\.?\d*$/;
            if (!decimalRegex.test(inputVDEFValue.value)) {
                inputVDEFValue.value = inputVDEFValue.value.slice(0, -1);
            }
        }

        function formatNumber(number) {
            return Utils.formatNumber(number);
        }

        function getBonusTON() {
            if (winStreak.value < 3) {
                return 0;
            } else if (winStreak.value >= 3 && winStreak.value < 5) {
                return 0.1;
            } else if (winStreak.value >= 5 && winStreak.value < 10) {
                return 0.5;
            } else if (winStreak.value === 10) {
                return 1;
            } else {
                return null;
            }
        }


        function predictionRankingToggle(tab) {
            if (tab === 1) {
                historyBtn.value.classList.add('border');
                historyBtn.value.querySelector('h1').classList.remove('text-[#D9D9D9]');
                historyBtn.value.querySelector('h1').classList.add('text-[#CDFB2D]');
                leaderboardBtn.value.classList.remove('border');
                leaderboardBtn.value.querySelector('h1').classList.remove('text-[#CDFB2D]');
                leaderboardBtn.value.querySelector('h1').classList.add('text-[#D9D9D9]');
                leaderboard.value.classList.add('opacity-0');
                setTimeout(() => {
                    leaderboard.value.classList.add('hidden');
                    history.value.classList.remove('hidden');
                    setTimeout(() => {
                        history.value.classList.remove('opacity-0');
                    }, 10);
                }, 300);
            }
            if (tab === 2) {
                historyBtn.value.classList.remove('border');
                historyBtn.value.querySelector('h1').classList.remove('text-[#CDFB2D]');
                historyBtn.value.querySelector('h1').classList.add('text-[#D9D9D9]');
                leaderboardBtn.value.classList.add('border');
                leaderboardBtn.value.querySelector('h1').classList.remove('text-[#D9D9D9]');
                leaderboardBtn.value.querySelector('h1').classList.add('text-[#CDFB2D]');
                history.value.classList.add('opacity-0');
                setTimeout(() => {
                    history.value.classList.add('hidden');
                    leaderboard.value.classList.remove('hidden');
                    setTimeout(() => {
                        leaderboard.value.classList.remove('opacity-0');
                    }, 10);
                }, 300);
            }
        }

        function initPrediction() {
            socket = socketConnect();
            socket.on('bitcoin_price', function (data) {
                currentBtcPrice.value = data.price;
            });
            startPredictionController();
            predictionResultController();
            initOdometer();
        }

        async function fetchAllDataPrediction() {
            try {
                const { history, leaderboard, userRank, userInfo } = await definAPI.getPredictionData();
                historyData.value = history;
                leaderboardData.value = leaderboard;
                userRankData.value = userRank;
                freePredictionLeft.value = userInfo.totalPrediction;
                winStreak.value = userInfo.predictionStreak ? userInfo.predictionStreak : 0;
                totalTONWon.value = userInfo.totalTon ? userInfo.totalTon : 0;
            } catch (error) {
                console.log(error);
            }
        }

        function dataEventListener() {
            document.addEventListener("dataEvent", async (event) => {
                const data = event.detail.message;
                if (data.callback === -1) {
                    initPrediction();
                    fetchAllDataPrediction();
                    yourBalance.value = state.getUserInfoFromServer().totalPoints;
                }
            });
        }

        function showPrediction() {
            predictionFrame.value.classList.remove('hidden');
            setTimeout(() => {
                predictionFrame.value.classList.remove('opacity-0');
            }, 10);
            if (freePredictionLeft.value <= 0) {
                gamePlayToggle(2);
            }
        }

        function hidePrediction() {
            predictionFrame.value.classList.add('opacity-0');
            setTimeout(() => {
                predictionFrame.value.classList.add('hidden');
            }, 700);
        }

        function startPredictionController() {
            socket.on('prediction_saved', function (data) {
                console.log(data);
                if (data.success) {
                    const time = data.data.time_prediction;
                    startCountdown(0, time, () => {
                        console.log("Countdown prediction...");
                    });
                    serverConfirmPredictAction.value = true;
                }
            });
        }

        function predictionResultController() {
            socket.on('prediction_result', function (data) {
                console.log(data);
                showGamePlayResult(data.isCorrect, data.pointsEarned);
                isPredictionRunning.value = false;
            });
        }

        function waitServerConfrimPredict(callback, timeout = 5000) {
            const startTime = Date.now();

            const check = setInterval(() => {
                if (serverConfirmPredictAction.value) {
                    serverConfirmPredictAction.value = false;
                    clearInterval(check);
                    callback();
                } else if (Date.now() - startTime >= timeout) {
                    clearInterval(check);
                    Utils.toastError('An error occurred!');
                }
            }, 5);
        }


        expose({
            predictionFrame,
            showPrediction,
            hidePrediction
        });


        onMounted(() => {
            dataEventListener();
        });

        return {
            predictionFrame,
            timeMinutes,
            timeSeconds,
            ticketRemaining,
            totalTONwon,
            freeTicketGameplay,
            vDEFTicketGameplay,
            freeTicketBtn,
            vDEFTicketBtn,
            gamePlay,
            gamePlayToggle,
            lockPriceFreeGamePlay,
            freeGamePlayPrediction,
            gainFreeGameplayBtn,
            dropFreeGameplayBtn,
            freeGameplayBtnContainer,
            lockPriceFreeGamePlayCurrent,
            gamePlayResult,
            winPrediction,
            prizeWonValue,
            hideGamePlayResult,
            freePredictionLeft,
            totalTONWon,
            btcPriceFreeGamePlay,
            freeBtnPriceInstance,
            currentBtcPrice,
            btcPricevDEFGamePlay,
            vDEFBtnPriceInstance,
            lockPriceVDEFGamePlay,
            lockPriceVDEFGamePlayCurrent,
            enterGainBtn,
            enterDropBtn,
            vDEFMultiplier,
            yourBalance,
            prizePool,
            enterVDEFPredictionPopup,
            inputVDEF,
            showVDEFPredictionPopup,
            vDEFTicket,
            cancelVDEFPrediction,
            vDEFGamePlayCurrentSelectIndex,
            confirmVEDFPrediction,
            enterVDEFContainer,
            validateDecimalInput,
            inputVDEFValue,
            typeGamePlay,
            formatNumber,
            btcPriceDisplay,
            winStreak,
            getBonusTON,
            historyBtn,
            leaderboardBtn,
            predictionRankingToggle,
            historyData,
            leaderboardData,
            userRankData,
            history,
            leaderboard,
            showPrediction,
            hidePrediction,
            serverConfirmPredictAction
        }
    }
}
</script>

<style scoped>
.box-shadow-gain {
    box-shadow: 0px 22px 44px 0px rgba(98, 182, 85, 0.40);
}

.box-shadow-drop {
    box-shadow: 0px 12px 32px 0px rgba(209, 42, 40, 0.44);
}

.odometer {
    vertical-align: baseline !important;
    font-size: 5.5vh;
}

.font-Helvetica {
    font-family: "Helvetica Neue", sans-serif;
}

input::placeholder {
    color: #141414;
    opacity: 1;
}
</style>