<template>
  <div class="w-screen h-screen min-h-screen flex justify-center items-center bg-black">
    <div
      :class="isLandscape ? 'relative w-full h-full overflow-hidden' : 'relative aspect-[9/16] h-full overflow-hidden'">

      <Loading />
      <Registers ref="registerSceenRef" />
      <Ages ref="agesSceenRef" />
      <Dashboard ref="dashboardSceenRef" />
      <Earn ref="earnSceenRef" />
      <Prediction ref="predictionSceenRef" />
      <Leaderboard ref="leaderboardSceenRef" />
      <Referral ref="referralSceenRef" />

      <!-- menu top -->
      <div ref="menuTopRef"
        class="absolute top-0 left-0 w-full h-[10%] flex justify-center items-center transition-all duration-300 opacity-0 hidden">
        <div class="w-[90%] h-[30%] flex justify-between items-center">
          <div ref="changeStateMenuRef" class="w-1/2 h-full transition-all duration-300">
            <div v-if="!topMenuState" class="h-full w-full flex gap-[5%] justify-start items-center">
              <img src="./assets/img/points.webp" class="h-full">
              <h1 class="font-montserrat text-[#CDFB2D] text-[1.6vh] font-[500] leading-none">{{ userName }}</h1>
            </div>
            <div v-else class="h-full w-full flex gap-[5%] justify-start items-center">
              <img @click="backButtonClick" src="./assets/img/back-btn.webp" class="h-full">
              <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">{{ titleSubMenu }}</h1>
            </div>
          </div>
          <div class="h-full w-1/2 flex gap-[5%] justify-end items-center">
            <div
              class="h-full w-fit flex justify-center items-center border border-[#d9d9d936] pl-[3%] pr-[4%] rounded-3xl box-shadow opacity-0">
              <img src="./assets/img/xDefin.webp" class="h-[70%]">
              <h1 class="font-montserrat text-[#D9D9D9] text-[1.7vh] font-[600] leading-none ml-[7%] mt-[2%]">
                {{ userTotalPoints }}</h1>
            </div>
            <div @click="settingClick" class="relative h-full w-fit">
              <img src="./assets/img/setting.webp" class="h-full">
            </div>
          </div>
        </div>
      </div>

      <!-- menu bottom -->
      <div ref="menuBottomRef"
        class="absolute bottom-0 left-0 w-full h-[10%] flex items-center transition-all duration-300 bg-[#141414] rounded-t-xl z-20 opacity-0">
        <div class="w-full h-[85%] flex justify-center items-center gap-[10%]">
          <img @click="activeBtnMenu(1)" src="./assets/img/dashboard-btn-active.webp"
            :class="dashboardBtnState ? 'h-[50%]' : 'h-[50%] grayscale-[100%]'">
          <img @click="activeBtnMenu(2)" src="./assets/img/earn-btn-active.webp"
            :class="earnBtnState ? 'h-[50%]' : 'h-[50%] grayscale-[100%]'">
          <div @click="activeBtnMenu(5)" class="relative h-[120%] w-auto -translate-y-[25%]">
            <img src="./assets/img/defingame-mining-bt.webp" class="h-full w-auto animate-spin-slow">
            <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center">
              <img src="./assets/img/logo.webp" class="w-[60%] -translate-y-[5%]">
            </div>
          </div>
          <img @click="activeBtnMenu(3)" src="./assets/img/leaderboard-btn-active.webp"
            :class="leaderboardBtnState ? 'h-[50%]' : 'h-[50%] grayscale-[100%]'">
          <img @click="activeBtnMenu(4)" src="./assets/img/referral-btn-active.webp"
            :class="referralBtnState ? 'h-[50%]' : 'h-[50%] grayscale-[100%]'">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Loading from './screen/Loading.vue'
import Registers from './screen/Registers.vue'
import Ages from './screen/Ages.vue'
import Dashboard from './screen/Dashboard.vue'
import Earn from './screen/Earn.vue'
import Leaderboard from './screen/Leaderboard.vue'
import Referral from './screen/Referral.vue'
import Prediction from './screen/Prediction.vue'
import { useState } from './assets/controller/state';
import * as Utils from './assets/controller/utilities';

export default {
  name: 'App',
  components: {
    Loading,
    Registers,
    Ages,
    Dashboard,
    Earn,
    Leaderboard,
    Referral,
    Prediction
  },
  computed: {
    isLandscape() {
      if (window.innerWidth / window.innerHeight < 0.7) {
        return true
      }
      return false
    }
  },
  setup() {
    const state = useState();
    const registerSceenRef = ref(null);
    const agesSceenRef = ref(null);
    const dashboardSceenRef = ref(null);
    const earnSceenRef = ref(null);
    const leaderboardSceenRef = ref(null);
    const referralSceenRef = ref(null);
    const dashboardBtnState = ref(false);
    const earnBtnState = ref(false);
    const leaderboardBtnState = ref(false);
    const referralBtnState = ref(false);
    const menuTopRef = ref(null);
    const menuBottomRef = ref(null);
    const menuBtnClickIndex = ref(0);
    const userName = ref('');
    const topMenuState = ref(false);
    const titleSubMenu = ref('');
    const backButton = ref(-1);
    const changeStateMenuRef = ref(null);
    const userTotalPoints = ref(12350);
    const predictionSceenRef = ref(null);

    function waitDashboardReady(callback) {
      const checkInterval = setInterval(() => {
        if (state.getLoadingState() && state.getUserInfoFromServer().teleAge) {
          clearInterval(checkInterval);
          callback();
        }
      }, 10);
    }

    function activeBtnMenu(btn) {
      if (!topMenuState.value && menuBtnClickIndex.value == btn) {
        return;
      }
      deactiveBtnMenu();
      hideAllFrame();
      if (topMenuState.value) {
        changeStateMenuRef.value.classList.add('opacity-0');
        setTimeout(() => {
          topMenuState.value = false;
          changeStateMenuRef.value.classList.remove('opacity-0');
        }, 300);
      }
      if (btn == 1) {
        dashboardBtnState.value = true;
        setTimeout(() => {
          dashboardSceenRef.value.showDashboard();
        }, 700);
      }
      if (btn == 2) {
        earnBtnState.value = true;
        setTimeout(() => {
          earnSceenRef.value.showEarnFrame();
        }, 700);
      }
      if (btn == 3) {
        leaderboardBtnState.value = true;
        setTimeout(() => {
          leaderboardSceenRef.value.showLeaderboardFrame();
        }, 700);
      }
      if (btn == 4) {
        referralBtnState.value = true;
        setTimeout(() => {
          referralSceenRef.value.showReferralFrame();
        }, 700);
      }
      if (btn == 5) {
        setTimeout(() => {
          predictionSceenRef.value.showPrediction();
        }, 700);
      }
      menuBtnClickIndex.value = btn;
    }

    function deactiveBtnMenu() {
      dashboardBtnState.value = false;
      earnBtnState.value = false;
      leaderboardBtnState.value = false;
      referralBtnState.value = false;
    }

    function hideAllFrame() {
      dashboardSceenRef.value.hideDashboard();
      earnSceenRef.value.hideEarnFrame();
      leaderboardSceenRef.value.hideLeaderboardFrame();
      referralSceenRef.value.hideReferralFrame();
      predictionSceenRef.value.hidePrediction();
      resetFrame();
    }

    function showMenuTopAndBottom() {
      userName.value = state.getUserInfoFromServer().teleAccount;
      userTotalPoints.value = state.getUserInfoFromServer().totalPoints;
      setTimeout(() => {
        menuTopRef.value.classList.remove('hidden');
        setTimeout(() => {
          menuTopRef.value.classList.remove('opacity-0');
        }, 10);
        dashboardBtnState.value = true;
        menuBottomRef.value.classList.remove('opacity-0');
      }, 300);
    }

    function menuEventListener() {
      document.addEventListener("menuEvent", (event) => {
        changeStateMenuRef.value.classList.add('opacity-0');
        const menuData = event.detail.message;
        setTimeout(() => {
          titleSubMenu.value = menuData.title;
          backButton.value = menuData.backButton;
          topMenuState.value = true;
          changeStateMenuRef.value.classList.remove('opacity-0');
        }, 300);
      });
    }

    function backButtonClick() {
      resetFrame();
    }

    function resetFrame() {
      if (backButton.value == -1) {
        return;
      }
      Utils.menuResponseDispatchEvent(backButton.value);
      changeStateMenuRef.value.classList.add('opacity-0');
      setTimeout(() => {
        topMenuState.value = false;
        changeStateMenuRef.value.classList.remove('opacity-0');
      }, 300);
      backButton.value = -1;
    }

    function notiClick() {
      Utils.toastInfo('We are cooking it!');
    }

    function searchClick() {
      Utils.toastInfo('We are cooking it!');
    }

    function walletClick() {
      Utils.toastInfo('We are cooking it!');
    }

    function settingClick() {
      Utils.toastInfo('We are cooking it!');
    }

    function updatePointsListener() {
      document.addEventListener("pointsEvent", async (event) => {
        const data = event.detail.message;
        if (data.type === 'add') {
          state.getUserInfoFromServer().totalPoints += data.value;
          userTotalPoints.value = state.getUserInfoFromServer().totalPoints;
        }
      });
    }

    onMounted(() => {
      menuEventListener();
      updatePointsListener();
      waitDashboardReady(() => {
        showMenuTopAndBottom();
      });
    });

    return {
      registerSceenRef,
      agesSceenRef,
      dashboardSceenRef,
      earnSceenRef,
      leaderboardSceenRef,
      referralSceenRef,
      activeBtnMenu,
      dashboardBtnState,
      earnBtnState,
      leaderboardBtnState,
      referralBtnState,
      menuTopRef,
      menuBottomRef,
      userName,
      topMenuState,
      titleSubMenu,
      backButton,
      changeStateMenuRef,
      backButtonClick,
      notiClick,
      searchClick,
      walletClick,
      settingClick,
      userTotalPoints,
      predictionSceenRef
    }
  }
}
</script>

<style>
* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  overflow: hidden;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.scrollbar-track {
  display: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'Azonix';
  src: url('./assets/fonts/Azonix.otf') format('opentype');
}

.font-azonix {
  font-family: "Azonix", sans-serif;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin 2s linear infinite;
}

.toast {
  background-color: #141414 !important;
}
</style>
